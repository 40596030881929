import React from 'react'

import {
  Button,
  CloudinaryImage,
  Layout,
  TitleMedium,
} from 'ethos-design-system'
import PropTypes from 'prop-types'

import { cmsModuleAnalytics } from '../../../lib/@getethos/analytics/analyticsEvents'
import styles from './LogoSummary.module.scss'

export const LogoSummary = ({ moduleData }: any) => {
  const {
    heading,
    subheading,
    buttonText,
    buttonUrl,
    newTab,
    groupImage,
    imageAlt,
  } = moduleData

  const handleCtaClick = () => {
    let utmSource: any = 'none'
    if (typeof window !== 'undefined') {
      const urlSearchParams = new URLSearchParams(window.location.search)
      if (urlSearchParams.has('utm_source')) {
        utmSource = urlSearchParams.get('utm_source')
      }
    }

    cmsModuleAnalytics.ctaClicked({
      properties: {
        module: 'Logo Summary',
        ctaLabel: buttonText,
        clickthroughUrl: buttonUrl,
        utmSource: utmSource,
      },
    })
    if (!newTab) {
      window.scrollTo(0, 0)
    }
    window.open(buttonUrl, newTab ? '_blank' : '_self')
  }

  return (
    <Layout.HorizontallyPaddedContainer>
      <div className={styles.outerWrapper}>
        <div className={styles.contentWrapper}>
          <div className={styles.textContainer}>
            <div className={styles.heading}>
              <TitleMedium.Sans.Medium500>{heading}</TitleMedium.Sans.Medium500>
            </div>
            <div className={styles.subheading}>{subheading}</div>
            <div className={styles.ctaAbove}>
              <Button.Medium.Black fullWidth onClick={handleCtaClick}>
                {buttonText}
              </Button.Medium.Black>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <CloudinaryImage
              width={[550, 875, 720, 782]}
              height={[196, 313, 257, 263]}
              publicId={groupImage}
              alt={imageAlt}
            />
          </div>
          <div className={styles.ctaBelow}>
            <Button.Medium.Black fullWidth onClick={handleCtaClick}>
              {buttonText}
            </Button.Medium.Black>
          </div>
        </div>
      </div>
    </Layout.HorizontallyPaddedContainer>
  )
}

LogoSummary.propTypes = {
  moduleData: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonUrl: PropTypes.string.isRequired,
    newTab: PropTypes.bool,
    groupImage: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
  }).isRequired,
}

export default LogoSummary
